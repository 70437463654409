import React from 'react'

const Navigation = () => {
  return (
    <nav>
      <div className="container navWrapper">

        <div className='navLogo'>
          <h1>TAXES BY ME TJR</h1>
          <p>Federal and State Tax Preparation</p>
        </div>

        <div className='navButtons'>
          <ul>
            <li>
              <a href="#forms" className="p-2 navItem" aria-current="page">Forms</a>
            </li>
            <li>
              <a href="#faq" className="p-2 navItem">FAQ</a>
            </li>
            <li>
              <a href="#contactUs" className="p-2 navItem">Contact Us</a>
            </li>
          </ul>
        </div>

      </div>
    </nav>
  )
}

export default Navigation