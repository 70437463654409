import React from 'react'
import BackgroundImageMobile from '../../assets/Hero-Banner-Mobile.png'
import BackgroundImage from '../../assets/Hero-Banner-Desktop.png'

const HeroBanner = () => {
    return (
        <section className='heroCont'>
            <img className='desktop' src={BackgroundImage} />
            <img className='mobile' src={BackgroundImageMobile} />
            <h2 className='aboutMessage'>Serving our communities tax <br className='mobileBR' /> preparation needs since 2001</h2>
        </section>
    )
}

export default HeroBanner