import React from 'react'

const Footer = () => {
    return (
        <footer className="m-5 w-full">
            <div className="text-center">
                <h2 className='text-base font-bold sm:text-sm'>© 2022-2025 TAXES BY ME.TJR&trade; All Rights Reserved.</h2>
            </div>
        </footer>
    )
}
export default Footer