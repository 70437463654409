import React, { useState, useEffect } from 'react';
import html2pdf from 'html2pdf.js'; // Make sure to install this dependency

// Import your form components
import TaxPrepListForm from './TaxPrepListForm';
import GeneralEngagementForm from './GeneralEngagementForm';
import AllTaxPayersForm from './AllTaxPayersForm';
import FillableTaxInfo from './FillableTaxInfo';

const Forms = () => {
  const [currentForm, setCurrentForm] = useState('');



  useEffect(() => {
    if (currentForm) {
      const formElement = document.getElementById(currentForm);
      if (formElement && currentForm !== 'tax-info-sheet') {
        var options = {
          margin: .5,
          filename: 'myfile.pdf',
          image: { type: 'png', quality: 1 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };
        // Convert the form to PDF
        html2pdf().from(formElement).set(options).output('blob').then((blob) => {
          // Create a URL for the blob and open it in a new tab
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
        });

        // Reset the currentForm to avoid immediate re-download
        setCurrentForm('');
      }
    }
  }, [currentForm]);

  return (
    <section className='formsCont' id='forms'>
      <h2 className='h2 pt-2'>Forms</h2>
      <div className='formBtns'>
        <button className='formBtn' onClick={() => setCurrentForm('tax-info-sheet')}>Tax Info Form</button>
        <button className='formBtn' onClick={() => setCurrentForm('tax-prep-list')}>Tax Prep Form</button>
        <button className='formBtn' onClick={() => setCurrentForm('general-engagement')}>General Engagement Form</button>
        <button className='formBtn' onClick={() => setCurrentForm('tax-questions')}>All Tax Payers Questions</button>
      </div>
      <div className='formContainer'>
        {currentForm === 'tax-questions' && <div id='tax-questions'><AllTaxPayersForm /></div>}
        {currentForm === 'tax-info-sheet' && <div id='tax-info-sheet'><FillableTaxInfo /></div>}
        {currentForm === 'tax-prep-list' && <div id='tax-prep-list'><TaxPrepListForm /></div>}
        {currentForm === 'general-engagement' && <div id='general-engagement'><GeneralEngagementForm /></div>}
      </div>
    </section>
  );
}

export default Forms;
