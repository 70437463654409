import React from 'react'

const AllTaxPayersForm = () => {
    return (
        <div className='formWrapper taxPayersForm' style={{ width: "750px", fontSize: "11px" }}>
            <div className='flex items-center justify-between'>
                <div className='font-medium text-3xl text-left underline pb-2'>Questions - All Taxpayers</div>
                <div className='text-xs'>(Provide related statements or other documentation.)</div>
            </div>

            <div>
                <p>"You" refers to both taxpayer and spouse - ask your preparer if unsure about a question.</p>
            </div>

            <div className='font-medium text-start underline text-base pb-2'>Lifestyle & Taxes</div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you pay or receive alimony in 2024?
                </div>

                <div className='border p-1'>
                    Recipent's SSN
                </div>

                <div className='border p-1'>
                    Date of divorce or separation
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you purchase health insurance through a public exchange/marketplace? (Provide Form 1095-A)
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you purchase a new or used energy-efficient, hybrid, or electric car, or van?
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Would you like to allow your tax preparer or another person to discuss your return with the IRS? <br />
                    <i className='pr-32'>Designee's name</i>
                    <i className='pr-32'>Phone Number</i>
                    <i>PIN (any five digits)</i>
                </div>
            </div>

            <div className='font-medium text-start underline text-base pb-2'>Children & Education</div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Were any children born or adopted in 2024? (Provide statement for other expenses.)
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Were any children attending college? (ProvideForm 1098-T and Form 1098-E.) <br />
                    <i className='pr-14'>Year in college</i>
                    <span className='pr-14'>Paid by you: <i>Tuition $</i></span>
                    <i className='pr-14'>Books $</i>
                    <i className='pr-14'>Student loan interest $</i>
                    <br />
                    <i className='pr-14'>Year in college</i>
                    <span className='pr-14'>Paid by you: <i>Tuition $</i></span>
                    <i className='pr-14'>Books $</i>
                    <i className='pr-14'>Student loan interest $</i>
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you pay for child or dependent care so you could work or go to school? <i>(Provide statement if applicable)</i> <br />
                    <i className='pr-60'>Name of provider</i>
                    <i className='pr-14'>EIN or SSN</i> <br />
                    <i className='pr-72'>Address</i>
                    <i>Amount paid $</i>
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you make any contributions to a 529 plan in 2024?
                </div>
            </div>

            <div className='font-medium text-start underline text-base pb-2'>Investments</div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you, or will you, contribute any money to an IRA for 2024?
                </div>

                <div className="border p-1">
                    <span className='pr-16'>Traditional IRA</span> Roth IRA
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you roll over any amounts from a retirement account in 2024?
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you sell or transfer any stock or sell rental or investment property?
                </div>
            </div>

            <div className='font-medium text-start underline text-base pb-2'>Deductions</div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you make any charitable contributions in 2024? If yes, provide details.
                </div>
            </div>

            <div className='font-medium text-start underline text-base pb-2'>Business</div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you work from a home office or use your car for your business?
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Do you own a business or an interest in a partnership, corporation, LLC, farming activities, or other venture?
                </div>
            </div>

            <div className='font-medium text-start underline text-base pb-2'>Home</div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you purchase or sell a main home during the year? If yes, provide closing statement.
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    If you sold a home, did you claim the First-Time Homebuyer Credit when it was purchased? If yes, provide details.
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    Did you make any new energy-efficient improvements to your home? If yes, provide details
                </div>
            </div>

            <div className='flex justify-center items-stretch'>
                <div className='yesNo border p-1'>
                    <p>Yes</p>
                    <p>No</p>
                </div>
                <div className='border p-1'>
                    If you sold a home, did you live in it for the last 2 out of 5 years? If so and you are single, <br /> was the profit less than 250,000 or married filing jointly profit less than 500,000?
                </div>
            </div>

            <div className='py-2 border-black border-b-2'>
                <b className='pr-14'>State information</b>
                <span className='pr-14'>Full-year resident</span>
                <span className='pr-14'>Part-year resident</span>
                <span className='pr-14'>Nonresident</span>
                <span className='pr-14'>School district</span>
            </div>

            <div className='py-2 border-black border-b-2'>
                <span className='pr-40'>State of residence during 2024 and dates</span>
                <span className='pr-14'>Do you rent or own your home?</span>
                <span className='pr-14'>Rent</span>
                <span className='pr-14'>Own</span> <br />
                <i className='pr-14 pl-96'>Total rent paid $</i>
                <span className='pr-16'>Includes heat?</span>
                <span className='pr-8'>Yes</span>
                <span>No</span>
            </div>

        </div>


    )
}

export default AllTaxPayersForm