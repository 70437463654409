import React, { forwardRef, useState, useRef } from 'react'
import ReactToPrint from 'react-to-print';

const TaxInfoSheetForm = forwardRef((ref, onChange) => {
    const componentRef = useRef();
    const [ssn, setSSN] = useState('');
    const [spouseSsn, setSpouseSSN] = useState('');
    const [primaryPhone, setPrimaryPhone] = useState('');
    const [secondaryPhone, setSecondaryPhone] = useState('');
    const [spousePrimaryPhone, setSpousePrimaryPhone] = useState('');
    const [spouseSecondaryPhone, setSpouseSecondaryPhone] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [spouseBirthdate, setSpouseBirthdate] = useState('');
    const [spouseIssueDate, setSpouseIssueDate] = useState('');
    const [spouseExpirationDate, setSpouseExpirationDate] = useState('');
    const [email, setEmail] = useState('');
    const [spouseEmail, setSpouseEmail] = useState('');
    const [dependent1Birthdate, setDependent1Birthdate] = useState('');
    const [dependent2Birthdate, setDependent2Birthdate] = useState('');
    const [dependent3Birthdate, setDependent3Birthdate] = useState('');
    const [dependent4Birthdate, setDependent4Birthdate] = useState('');
    const [dependent1SSN, setDependent1SSN] = useState('');
    const [dependent2SSN, setDependent2SSN] = useState('');
    const [dependent3SSN, setDependent3SSN] = useState('');
    const [dependent4SSN, setDependent4SSN] = useState('');

    function formatSsn(value) {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,3})(\d{0,2})(\d{0,4})$/);
        if (match) {
            return `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
        }
        return value;
    }

    function formatPhone(value) {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            return `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
        }
        return value;
    }

    function formatDate(value) {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
        if (match) {
            return `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
        }
        return value;
    }

    function validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    function handleSsnChange(e, ssnType) {
        const formattedSsn = formatSsn(e.target.value);
        if (ssnType === 'primary') {
            setSSN(formattedSsn);
        } else if (ssnType === 'spouse') {
            setSpouseSSN(formattedSsn);
        } else if (ssnType === 'dependent1') {
            setDependent1SSN(formattedSsn);
        } else if (ssnType === 'dependent2') {
            setDependent2SSN(formattedSsn);
        } else if (ssnType === 'dependent3') {
            setDependent3SSN(formattedSsn);
        } else if (ssnType === 'dependent4') {
            setDependent4SSN(formattedSsn);
        }
    }

    function handlePhoneChange(e, phoneType) {
        const formattedPhone = formatPhone(e.target.value);
        if (phoneType === 'primary') {
            setPrimaryPhone(formattedPhone);
        } else if (phoneType === 'secondary') {
            setSecondaryPhone(formattedPhone);
        } else if (phoneType === 'spousePrimary') {
            setSpousePrimaryPhone(formattedPhone);
        } else if (phoneType === 'spouseSecondary') {
            setSpouseSecondaryPhone(formattedPhone);
        }
    }

    function handleDateChange(e, dateType) {
        const formattedDate = formatDate(e.target.value);
        if (dateType === 'birthdate') {
            setBirthdate(formattedDate);
        } else if (dateType === 'issueDate') {
            setIssueDate(formattedDate);
        } else if (dateType === 'expirationDate') {
            setExpirationDate(formattedDate);
        } else if (dateType === 'spouseBirthdate') {
            setSpouseBirthdate(formattedDate);
        } else if (dateType === 'spouseIssueDate') {
            setSpouseIssueDate(formattedDate);
        } else if (dateType === 'spouseExpirationDate') {
            setSpouseExpirationDate(formattedDate);
        } else if (dateType === 'dependent1') {
            setDependent1Birthdate(formattedDate);
        } else if (dateType === 'dependent2') {
            setDependent2Birthdate(formattedDate);
        } else if (dateType === 'dependent3') {
            setDependent3Birthdate(formattedDate);
        } else if (dateType === 'dependent4') {
            setDependent4Birthdate(formattedDate);
        }
    }

    function handleEmailChange(e, emailType) {
        const emailValue = e.target.value;
        if (emailType === 'primaryEmail') {
            setEmail(emailValue);
        } else if (emailType === 'spouseEmail') {
            setSpouseEmail(emailValue);
        }
    }

    function handleEmailBlur(e, emailType) {
        const emailValue = e.target.value;
        if (!validateEmail(emailValue)) {
            alert('Invalid email format, please try again');
            e.target.style.color = 'red';
            setTimeout(() => {
                e.target.style.color = 'black';
            }, 2000);
        } else {
            e.target.style.color = 'black';
        }
    }

    return (
        <div>
            <form ref={componentRef} className='flex flex-col lg:text-xs min-w-full p-4 px-6 bg-white gap-2' id='form'>
                <label className='font-medium text-2xl text-center lg:text-xl lg:mb-1'>Tax Payer Information Sheet 2025 (Tax Year 2024)</label>
                <p className='noPrint font-bold text-xl md:text-xs bg-red-600 text-white text-center px-1'>
                    Kindly complete the form with all required information and print it upon completion. <br className='lg:hidden' />
                    If you have any questions or require assistance, please contact us at <a className='underline' href="tel:18166907997">(816) 690-7997.</a> 
                </p>

                <div className='flex flex-row gap-1'>
                    <label className='font-medium lg:min-w-max underline'>TAX PAYER INFORMATION</label>
                    <select className='lg:min-w-max border border-black'>
                        <option></option>
                        <option>Returning Client</option>
                        <option>New Client</option>
                    </select>
                </div>


                <div className='flex justify-center items-end gap-5 md:gap-1 border-4 border-black p-1 md:flex-col print:!flex-row'>
                    <div className='flex flex-col gap-1 w-1/2 md:w-full'>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>YOUR NAME:</label>
                            <input placeholder='John Doe' className='w-full pl-1 border border-transparent hover:border-black'></input>
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>SOCIAL SECURITY #:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='___-__-____'
                                value={ssn}
                                onChange={(e) => handleSsnChange(e, 'primary')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>BIRTHDATE:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='MM-DD-YYYY'
                                value={birthdate}
                                onChange={(e) => handleDateChange(e, 'birthdate')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>PRIMARY PHONE:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='123-456-7890'
                                value={primaryPhone}
                                onChange={(e) => handlePhoneChange(e, 'primary')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>SECONDARY PHONE:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='123-456-7890'
                                value={secondaryPhone}
                                onChange={(e) => handlePhoneChange(e, 'secondary')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>OCCUPATION:</label>
                            <input placeholder='e.g. Engineer' className='w-full pl-1 border border-transparent hover:border-black'></input>
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>DRIVER'S LICENSE #:</label>
                            <input className='w-full pl-1 border border-transparent hover:border-black' placeholder='WXXXXXXXXX'></input>
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>ISSUE DATE:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='MM-DD-YYYY'
                                value={issueDate}
                                onChange={(e) => handleDateChange(e, 'issueDate')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>EXPIRATION DATE:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='MM-DD-YYYY'
                                value={expirationDate}
                                onChange={(e) => handleDateChange(e, 'expirationDate')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>STATE DRIVER'S LICENSE ISSUED:</label>
                            <input className='w-full pl-1 border border-transparent hover:border-black' placeholder='e.g. Missouri'></input>
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>EMAIL ADDRESS:</label>
                            <input
                                className="w-full pl-1 border border-transparent hover:border-black"
                                placeholder='name@example.com'
                                value={email}
                                onChange={(e) => handleEmailChange(e, 'primaryEmail')}
                                onBlur={(e) => handleEmailBlur(e, 'primaryEmail')}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-1 w-1/2 md:w-full'>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>SPOUSE'S NAME:</label>
                            <input placeholder='Jane Doe' className='w-full pl-1 border border-transparent hover:border-black'></input>
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>SOCIAL SECURITY #:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='___-__-____'
                                value={spouseSsn}
                                onChange={(e) => handleSsnChange(e, 'spouse')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>BIRTHDATE:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='MM-DD-YYYY'
                                value={spouseBirthdate}
                                onChange={(e) => handleDateChange(e, 'spouseBirthdate')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>PRIMARY PHONE:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='123-456-7890'
                                value={spousePrimaryPhone}
                                onChange={(e) => handlePhoneChange(e, 'spousePrimary')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>SECONDARY PHONE:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='123-456-7890'
                                value={spouseSecondaryPhone}
                                onChange={(e) => handlePhoneChange(e, 'spouseSecondary')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>OCCUPATION:</label>
                            <input placeholder='e.g. Teacher' className='w-full pl-1 border border-transparent hover:border-black'></input>
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>DRIVER'S LICENSE #:</label>
                            <input className='w-full pl-1 border border-transparent hover:border-black' placeholder='WXXXXXXXXX'></input>
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>ISSUE DATE:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='MM-DD-YYYY'
                                value={spouseIssueDate}
                                onChange={(e) => handleDateChange(e, 'spouseIssueDate')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>EXPIRATION DATE:</label>
                            <input
                                className='w-full pl-1 border border-transparent hover:border-black'
                                placeholder='MM-DD-YYYY'
                                value={spouseExpirationDate}
                                onChange={(e) => handleDateChange(e, 'spouseExpirationDate')}
                            />
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>STATE DRIVER'S LICENSE ISSUED:</label>
                            <input className='w-full pl-1 border border-transparent hover:border-black' placeholder='e.g. Missouri'></input>
                        </div>
                        <div className='flex items-center gap-1'>
                            <label className='italic font-medium min-w-max'>EMAIL ADDRESS:</label>
                            <input
                                className="w-full pl-1 border border-transparent hover:border-black"
                                placeholder='name@example.com'
                                value={spouseEmail}
                                onChange={(e) => handleEmailChange(e, 'spouseEmail')}
                                onBlur={(e) => handleEmailBlur(e, 'spouseEmail')}
                            />
                        </div>
                    </div>
                </div>


                <div className='flex justify-evenly gap-5 md:gap-1 border-4 border-black p-1 md:flex-col print:!flex-row'>
                    <div className='w-full flex flex-col gap-1 sm:pb-3'>
                        <label className='font-medium underline'>ADDRESS SECTION</label>
                        <div className='flex gap-1 justify-between'>
                            <label className='italic font-medium min-w-max'>STREET:</label>
                            <input placeholder='e.g. 1003 S Broadway' className='w-full pl-1 border border-transparent hover:border-black'></input>
                        </div>
                        <div className='flex gap-1 justify-between'>
                            <label className='italic font-medium min-w-max'>CITY:</label>
                            <input placeholder='e.g. Oak Grove' className='w-full pl-1 border border-transparent hover:border-black'></input>
                        </div>
                        <div className='flex gap-1 justify-between'>
                            <label className='italic font-medium min-w-max'>STATE:</label>
                            <input placeholder='e.g. Missouri' className='w-full pl-1 border border-transparent hover:border-black'></input>
                        </div>
                        <div className='flex gap-1 justify-between'>
                            <label className='italic font-medium min-w-max'>ZIP CODE:</label>
                            <input placeholder='e.g. 64075' className='w-full pl-1 border border-transparent hover:border-black'></input>
                        </div>
                    </div>
                    <div className='w-full flex flex-col gap-1'>
                        <div className='flex gap-1'>
                            <label className='font-medium sm:text-xs italic'>FILING STATUS:</label>
                            <select className='border border-black'>
                                <option value="SINGLE"></option>
                                <option value="SINGLE">SINGLE</option>
                                <option value="MARRIED FILING JOINT">MARRIED FILING JOINT</option>
                                <option value="MARRIED FILING SEPARATE">MARRIED FILING SEPARATE</option>
                                <option value="HEAD OF HOUSEHOLD">HEAD OF HOUSEHOLD</option>
                                <option value="SPOUSE DECEASED">SPOUSE DECEASED</option>
                                <option value="DIVORCED">DIVORCED</option>
                            </select>
                        </div>
                        <div className='flex'>
                            <label className='italic font-medium'>IF SPOUSE DECEASED after 1/1/2024 INPUT DATE HERE:<input placeholder='MM-DD-YYYY' className='border border-transparent hover:border-black ml-2 font-normal'></input></label>
                        </div>
                        <div className='flex'>
                            <label className='italic font-medium'>IF DIVORCED after 1/1/2020 INPUT DATE HERE:<input placeholder='MM-DD-YYYY' className='border border-transparent hover:border-black ml-2 font-normal'></input></label>
                        </div>
                    </div>
                </div>


                <div className='flex flex-col gap-1 border-4 border-black p-1'>
                    <div className='flex items-center gap-1'>
                        <label className='font-medium italic'>IF HEAD OF HOUSEHOLD NEED 1 OF THE FOLLOWING IN YOUR NAME:
                            <select className='border border-black font-normal ml-1'>
                                <option></option>
                                <option>PROPERTY TAX RECEIPT</option>
                                <option>MORTGAGE STATEMENT</option>
                                <option>RENTAL AGREEMENT</option>
                                <option>UTILITY BILL</option>
                            </select>
                        </label>
                    </div>
                    <div className='flex items-center gap-1'>
                        <label className=' font-medium italic'>IF DIVORCED, COPY OF DIVORCE DECREE IF CHILDREN ARE BEING CLAIMED:
                            <select className='border border-black font-normal ml-1'>
                                <option></option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </label>
                    </div>
                </div>


                <div className='border-4 border-black p-1'>
                    <label className='font-medium underline'>DEPENDENT INFORMATION (IF MORE THAN 4 DEPENDENTS, PRINT AND FILL IN ON BACK)</label>
                    <div className='flex justify-start gap-4 md:flex-col print:!flex-row'>
                        <div className='flex flex-col gap-1'>
                            <label className='font-medium italic'>NAME:</label>
                            <input placeholder='Dependent 1 Name' className='border border-transparent hover:border-black'></input>
                            <input placeholder='Dependent 2 Name' className='border border-transparent hover:border-black'></input>
                            <input placeholder='Dependent 3 Name' className='border border-transparent hover:border-black'></input>
                            <input placeholder='Dependent 4 Name' className='border border-transparent hover:border-black'></input>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='font-medium italic'>SOCIAL SECURITY #:</label>
                            <input
                                placeholder='Dependent 1 SS#'
                                className='border border-transparent hover:border-black'
                                value={dependent1SSN}
                                onChange={(e) => handleSsnChange(e, 'dependent1')}
                            ></input>
                            <input
                                placeholder='Dependent 2 SS#'
                                className='border border-transparent hover:border-black'
                                value={dependent2SSN}
                                onChange={(e) => handleSsnChange(e, 'dependent2')}
                            ></input>
                            <input
                                placeholder='Dependent 3 SS#'
                                className='border border-transparent hover:border-black'
                                value={dependent3SSN}
                                onChange={(e) => handleSsnChange(e, 'dependent3')}
                            ></input>
                            <input
                                placeholder='Dependent 4 SS#'
                                className='border border-transparent hover:border-black'
                                value={dependent4SSN}
                                onChange={(e) => handleSsnChange(e, 'dependent4')}
                            ></input>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='font-medium italic'>RELATIONSHIP:</label>
                            <input placeholder='Relationship' className='border border-transparent hover:border-black'></input>
                            <input placeholder='Relationship' className='border border-transparent hover:border-black'></input>
                            <input placeholder='Relationship' className='border border-transparent hover:border-black'></input>
                            <input placeholder='Relationship' className='border border-transparent hover:border-black'></input>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='font-medium italic'>BIRTHDATE:</label>
                            <input
                                placeholder='MM-DD-YYYY'
                                className='border border-transparent hover:border-black'
                                value={dependent1Birthdate}
                                onChange={(e) => handleDateChange(e, 'dependent1')}
                            ></input>
                            <input
                                placeholder='MM-DD-YYYY'
                                className='border border-transparent hover:border-black'
                                value={dependent2Birthdate}
                                onChange={(e) => handleDateChange(e, 'dependent2')}
                            ></input>
                            <input
                                placeholder='MM-DD-YYYY'
                                className='border border-transparent hover:border-black'
                                value={dependent3Birthdate}
                                onChange={(e) => handleDateChange(e, 'dependent3')}
                            ></input>
                            <input
                                placeholder='MM-DD-YYYY'
                                className='border border-transparent hover:border-black'
                                value={dependent4Birthdate}
                                onChange={(e) => handleDateChange(e, 'dependent4')}
                            ></input>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className=' font-medium italic'>DEPENDENT DAYCARE EXPENSE:</label>
                            <div>
                                <select className='border border-black'>
                                    <option></option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            <div>
                                <select className='border border-black'>
                                    <option></option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            <div>
                                <select className='border border-black'>
                                    <option></option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            <div>
                                <select className='border border-black'>
                                    <option></option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='sm:flex sm:flex-col border-4 border-black p-1 md:gap-1'>
                    <div className='flex items-center gap-1 md:justify-between'>
                        <label className='font-medium italic'>PROOF OF RESIDENCY OF DEPENDENT(S) (Example: HEALTH FORM, SCHOOL DOCUMENT):
                            <select className='border border-black ml-1 font-normal'>
                                <option></option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </label>
                    </div>
                    <div className='flex items-center gap-1 md:justify-between'>
                        <label className='font-medium italic'>IS DEPENDENT A STUDENT:
                            <select className='border border-black ml-1 font-normal'>
                                <option></option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </label>
                    </div>
                    <div className='flex items-center gap-1 md:justify-between'>
                        <label className='font-medium italic'>IS DEPENDENT A COLLEGE STUDENT: (IF YES, WILL NEED 1098T FORM)
                            <select className='border border-black ml-1 font-normal'>
                                <option></option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </label>
                    </div>
                    <div className='flex items-center gap-1 md:justify-between'>
                        <label className='font-medium italic'>DID YOU BUY OR SELL ANY VIRTUAL CURRENCY IN 2024:
                            <select className='border border-black ml-1 font-normal'>
                                <option></option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </label>
                    </div>
                    <div className='flex items-center gap-1 md:justify-between md:flex-col md:items-start print:!flex-row print:!items-center'>
                        <label className='font-medium italic'>DID YOU HAVE AN IRS ISSUES IDENTITY PROTECTION PIN NUMBER:
                            <select className='border border-black ml-1 font-normal'>
                                <option></option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </label>
                        <label className='font-medium italic'>IF YES, PLEASE PROVIDE:
                            <input placeholder='Enter Pin Number Here' className='border border-transparent hover:border-black ml-1 font-normal p-1 text-left'></input>
                        </label>
                    </div>
                    <div className='flex flex-col gap-1 md:justify-between'>
                        <div className='flex items-center gap-1'>
                            <label className='font-medium italic'>DO YOU HAVE HEALTH INSURANCE THROUGH THE MARKETPLACE(OBAMA CARE)?:
                                <select className='border border-black ml-1 font-normal'>
                                    <option></option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </label>
                        </div>
                        <label className='font-medium italic'>IF YOU HAVE HEALTH INSURANCE THROUGH THE MARKETPLACE, PLEASE PROVIDE FORM 1095A (CAN'T FIND YOUR FORM? CALL MARKETPLACE @ 1-800-318-2596 AND ASK FOR A REPLACEMENT) </label>
                    </div>
                </div>


                <div className='border-4 border-black p-1 flex flex-col gap-1'>
                    <label className='font-medium underline'>REFUND DIRECT DEPOSIT INFORMATION</label>
                    <div className='flex items-center gap-1'>
                        <label className='font-medium italic min-w-max'>BANK NAME:</label>
                        <input placeholder='e.g. Central Bank' className='pl-1 w-full border border-transparent hover:border-black'></input>
                    </div>
                    <div className='flex items-center gap-1'>
                        <label className='font-medium italic min-w-max'>TYPE OF ACCOUNT (CHECKING OR SAVINGS):</label>
                        <input placeholder='e.g. Checking' className='pl-1 w-full border border-transparent hover:border-black'></input>
                    </div>
                    <div className='flex items-center gap-1'>
                        <label className='font-medium italic min-w-max'>ROUTING NUMBER:</label>
                        <input placeholder='Routing Number' className='pl-1 w-full border border-transparent hover:border-black'></input>
                    </div>
                    <div className='flex items-center gap-1'>
                        <label className='font-medium italic min-w-max'>ACCOUNT NUMBER:</label>
                        <input placeholder='Account Number' className='pl-1 w-full border border-transparent hover:border-black'></input>
                    </div>
                </div>
            </form>
            <ReactToPrint
                trigger={() => <button className='bg-green-600 hover:bg-green-400 p-2 rounded-md text-white block mx-auto my-4'>Print/Download</button>}
                content={() => componentRef.current}
            />
        </div>


    )
})

export default TaxInfoSheetForm