import React from 'react'
import Clock from '../../assets/Clock-Icon.png'
import Address from '../../assets/Address-Icon.png'
import Phone from '../../assets/Phone-Email-Icon.png'

const ContactUs = () => {
  return (

    <section className="contactUs" id='contactUs'>
      <h2 className="h2">Contact Us</h2>
      <div className="contactWrapper">
        <div className="contactCard">
          <img src={Clock} alt="Clock icon" />
          <h3 className="h3">Hours of Operation</h3>
          <ul className='hoursUL'>
            <li className='hours'>Monday-Friday: <span className='hoursSpan'>9:00am-8:00pm</span></li>
            <li className='hours'>Saturday: <span className='hoursSpan'>9:00am-3:00pm</span></li>
            <li className='hours'>Sunday: <span className='hoursSpan'>Closed</span></li>
          </ul>
        </div>
        <div className="contactCard addressCard">
          <img src={Address} alt="GPS pin icon" />
          <h3 className="h3">Address</h3>
          <p>1003 S Broadway, Oak Grove, MO 64075</p>
          <a href="https://maps.app.goo.gl/breodfm4K6jbWbAy5" className='directionsBtn'>Get Directions</a>
        </div>
        <div className="contactCard phoneCard">
          <img src={Phone} alt="Phone icon" />
          <h3 className="h3">Schedule an Appointment</h3>
          <a href='TEL:816-690-7997"'><b>Phone: </b> <span>(816) 690-7997</span></a>
          <p><b>Fax: </b>(816) 625-1472</p>
          <p><b>Email:</b> <a href='mailto:taxesbymetjr@gmail.com' className='cursor-pointer underline'>taxesbymetjr@gmail.com</a></p>
        </div>
      </div>
    </section>
  )
}

export default ContactUs